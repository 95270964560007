import React, {useState} from 'react'


export default function TextForm(props) {
    const handleUpClick = () => {
        let newText = text.toUpperCase();
        setText(newText)
        props.showAlert('Converted to Uppercase', 'success')
    }
    const handleClearClick = () => {
        let newText = '';
        setText(newText)
        props.showAlert('All Text Has been Removed', 'success')
    }
    const handleCopy = () => {
        let newText = document.getElementById('exampleFormControlTextarea1');
        newText.select();
        navigator.clipboard.writeText(newText.value)
        props.showAlert('All Text Has been Coppied', 'success')
    }
    const handleExtraSpaces = () => {
        let newText = text.split(/[  ]+/)
        setText(newText.join(' '))
        props.showAlert('Extra Spaces Has been Removed', 'success')
    }
    const handlelowClick = () => {
        let newText = text.toLowerCase();
        setText(newText)
        props.showAlert('Converted to Lowercase', 'success')
    }
    const handleOnChange = (event) => {
        setText(event.target.value)
    }
    const [text, setText] = useState(''); 
    return (
        <div style={{color: props.mode === 'dark' ? 'white' : 'black'}}>
            <h1 className='text-center my-3'>Enter Your Text to Analyze</h1>
            <div className="form-group">
                <textarea className="form-control" value={text} onChange={handleOnChange} style={{backgroundColor: props.mode === 'dark' ? '#93a194' : 'white', color: props.mode === 'dark' ? 'white' : 'black'}}
                 id="exampleFormControlTextarea1" rows="8"></textarea>
            </div>
            <div className="buttonGroup my-3">
            <button disabled={text.length === 0} className="btn btn-primary mx-1 my-1" onClick={handleUpClick}>Covert to Uppercase</button>
            <button disabled={text.length === 0} className="btn btn-info mx-1 my-1" onClick={handlelowClick}>Covert to Lowercase</button>
            <button disabled={text.length === 0} className="btn btn-success mx-1 my-1" onClick={handleCopy}>Copy Text</button>
            <button disabled={text.length === 0} className="btn btn-primary mx-1 my-1" onClick={handleExtraSpaces}>Remove Extra Spaces</button>
            <button disabled={text.length === 0} className="btn btn-danger mx-1 my-1" onClick={handleClearClick}>Clear</button>
            </div>
            <h3 className='my-3'>Your Text Summary is: </h3>
            {/* <p><b>{text.length > 0 ? text.trim().split(' ').length : 0}</b> words and <b>{text.length}</b> characters with space <b>{text.split(' ').join('').length}</b> characters without space</p> */}
            <p><b>{text.split(/\s+/).filter((element) => {return element.length !== 0}).length}</b> words and <b>{text.length}</b> characters with space <b>{text.split(' ').join('').length}</b> characters without space</p>
        </div>
  )
}
