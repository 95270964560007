import React, { useState } from 'react';
import './App.css';
import Alert from './components/Alert';
import About from './components/About';
import Navbar from './components/Navbar';
import TextForm from './components/TextForm';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

function App() {
  const [mode, setMode] = useState('light');
  const [alert, setAlert] = useState(null);

  const showAlert = (message, type) => {
    setAlert({
      msg: message,
      type: type
    })
    setTimeout(() => {
      setAlert(null);
    }, 2000)
  }

  const toggleMode = () =>{
    if (mode === 'light') {
      setMode('dark')
      document.body.style.backgroundColor = '#113972';
      showAlert('Dark Mode On', 'success')
    }else{
      setMode('light')
      document.body.style.backgroundColor = 'white';
      showAlert('Lite Mode On', 'success')
    }
  }
  return (
    <>
    <Router>
    <Navbar mode={mode} toggleMode={toggleMode} />
      <div className="container my-2">
      <Alert alert={alert}/>
        <Routes>
          <Route exact path="/" element={<TextForm showAlert={showAlert} mode={mode}/>}/>
          <Route exact path="/about" element={ <About mode={mode} />}/>
          <Route path="*" element={<TextForm showAlert={showAlert} mode={mode}/>} />
        </Routes>
        </div>
    </Router>
    </>
  );
}

export default App;
