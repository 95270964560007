import React from 'react'

export default function About(props) {
  return (
    <>
    <h1 style={{color: props.mode === 'dark' ? 'white' : 'black'}} className='my-3'>About Us</h1>
    <div className="accordion" id="accordionExample" >
        <div className="accordion-item" style={{backgroundColor: props.mode === 'dark' ? '#93a194' : 'white', color: props.mode === 'dark' ? 'white' : 'black', borderColor: props.mode === 'dark' ? 'white' : ''}}>
            <h2 className="accordion-header" id="headingOne">
            <button style={{backgroundColor: props.mode === 'dark' ? '#93a194' : 'white', color: props.mode === 'dark' ? 'white' : 'black'}} className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                <strong>About My First App</strong>
            </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
            <div className="accordion-body">
                This is the first React App I have developed with the help of <strong>CodeWithHarry</strong> from <strong>Youtube</strong>. This App will help you to 
                Analyze your inputted text. You can convert text from Uppercase to Lowercase, Lowercase to Uppercase, Remove extra spaces from the paragraph and also Copy
                the text to clipboard.
            </div>
            </div>
        </div>
        <div className="accordion-item" style={{backgroundColor: props.mode === 'dark' ? '#93a194' : 'white', color: props.mode === 'dark' ? 'white' : 'black', borderColor: props.mode === 'dark' ? 'white' : ''}}>
            <h2 className="accordion-header" id="headingTwo">
            <button style={{backgroundColor: props.mode === 'dark' ? '#93a194' : 'white', color: props.mode === 'dark' ? 'white' : 'black'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <strong>About Me</strong>
            </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
            <div className="accordion-body">
            Hi! My name is <strong>Alkami</strong> from Bangladesh. I'm an engineer with a Bachelor of Science degree in Computer Science and Engineering. 
            I have experience in Web-based System Application Development using <strong>PHP (core and CodeIgniter framework), bootstrap, javascript, jquery, ajax, MySQL, nodeJs, React, MongoDB,</strong> and so on that are needed for the development. 
            I love learning and working with various companies on a wide range of projects expanding my knowledge and expertise on a daily basis. I hope we have the opportunity to work together.
            </div>
            </div>
        </div>
        <div className="accordion-item" style={{backgroundColor: props.mode === 'dark' ? '#93a194' : 'white', color: props.mode === 'dark' ? 'white' : 'black', borderColor: props.mode === 'dark' ? 'white' : ''}}>
            <h2 className="accordion-header" id="headingThree">
            <button style={{backgroundColor: props.mode === 'dark' ? '#93a194' : 'white', color: props.mode === 'dark' ? 'white' : 'black'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <strong>About Rumman IT Solution</strong>
            </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
            <div className="accordion-body">
                <a style={{color: 'red', fontWeight: 'bold'}} href='https://www.rummanitsolution.com/' target='_blank'>Rumman IT Solution</a> is my personal protfolio website. You can visit there to check all of my previous developed system.
            </div>
            </div>
        </div>
    </div>
    </>
  )
}
